import http from '@/plugins/http'

export default class EmploymentWorkingSchedule {
  static delete(workingScheduleKey, companyKey) {
    return http.delete(`employment-working-schedules/${workingScheduleKey}`, {
      params: {
        company_id: companyKey,
      },
    })
  }

  static async create(params) {
    return http.post(`employment-working-schedules`, params)
  }
}
