import http from '@/plugins/http'
import Employment from '@/models/employment/Employment'

export default class EmploymentProfile {
  static async show(company) {
    const { data: employment } = await http.get(
      `me/companies/${company.id}/employment`
    )

    return new Employment(employment, company)
  }
}
