import http from '@/plugins/http'

export default class Employments {
  static get(employmentKey, params) {
    return http.get(`employments/${employmentKey}`, {
      params: params,
    })
  }

  static getCalendarServices(employmentKey, params) {
    return http.get(`employments/${employmentKey}/synchronizations`, {
      params: params,
    })
  }

  static create(payload) {
    return http.post('employments', payload)
  }

  static invite(employmentKey, params) {
    return http.post(`employments/${employmentKey}/invitations`, params)
  }

  static find(params) {
    return http.get('employments', {
      params: params,
    })
  }

  static update(employmentKey, payload) {
    return http.put(`employments/${employmentKey}`, payload)
  }

  static sendPasswordResetLink(employment) {
    return http.post(`employments/${employment.id}/password/forgot`, {
      company: employment.company_id,
    })
  }

  static async delete(employmentKey, companyKey) {
    return http.delete(`employments/${employmentKey}`, {
      data: { company_id: companyKey },
    })
  }

  static async restore(employmentKey, companyKey) {
    return http.delete(`trashed-employments/${employmentKey}`, {
      data: { company_id: companyKey },
    })
  }
}
