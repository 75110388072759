<template>
  <div class="tw-flex tw-flex-col">
    <Loading loader="dots" :is-full-page="false" :active="isLoadingState" />

    <AppNavbar v-show="!isLoadingState" />

    <LeaveDatesTour v-if="!isLoadingState" />

    <div
      v-if="!isLoadingState"
      class="main-container tw-flex-wrap lg:tw-flex lg:tw-flex-no-wrap"
    >
      <RouterView
        :key="$route.fullPath"
        class="navbar-secondary-wrap tw-bg-white lg:tw-bg-transparent"
        name="secondary-nav"
      />

      <div class="lg:tw-flex-1 ie_app-container">
        <main>
          <div v-if="paymentErrorFeatureFlag && hasPaymentIssue && isAdmin">
            <div class="tw-mx-4">
              <PaymentIssue />
            </div>
          </div>
          <div v-else>
            <div v-if="!emailVerified">
              <div class="tw-mx-4">
                <AccountVerification />
              </div>
            </div>
            <div v-if="!hasProperSubscription">
              <div class="tw-mx-4">
                <NeedsSubscription />
              </div>
            </div>
            <div v-if="activeEmployment.hasWarnings">
              <div>
                <UnavailableAllowanceYears
                  :warning="
                    activeEmployment.warnings.whereUnavailableAllowanceYears()[0]
                  "
                />
              </div>
            </div>
            <div>
              <CalendarServiceReConnector
                v-for="failedCalendarService in failedCalendarServices"
                :key="failedCalendarService.id"
                :calendar="failedCalendarService"
              />
            </div>
          </div>

          <PortalTarget name="action-bar" />

          <transition name="page" mode="out-in">
            <RouterView class="tw-w-full tw-px-4" />
          </transition>
        </main>
      </div>
    </div>

    <VDialog :class="'prompt-dialog'" :click-to-close="false" />

    <AppModal v-if="!isLoadingState" />

    <HelpModal v-if="!isLoadingState" />

    <AppFooter v-if="!isLoadingState" />

    <SlideOver v-if="!isLoadingState" />
  </div>
</template>

<script>
import http, {
  HTTP_STATUS_AUTHENTICATION_EXPIRED,
  HTTP_STATUS_UNAUTHORIZED,
} from '@/plugins/http'
import { includes, isEqual } from 'lodash-es'
import AppModal from '@/components/AppModal'
import FeatureFlags from '@/mixins/FeatureFlags'
import HandleAuth from '@/mixins/HandleAuth'
import Subscription from '@/mixins/Subscription'
import AppFooter from '@/components/AppFooter'
import AppNavbar from '@/components/AppNavbar'
import HelpModal from '@/components/help/HelpModal'
import SlideOver from '@/components/SlideOver'
import LeaveDatesTour from '@/components/LeaveDatesTour'
import SystemCommonShortcuts from '@/mixins/page-shortcuts/CommonShortcuts'
import SlideoutPanel from '@/layouts/SlideoutPanel'
import Feed from '@/api/feed/Feed'
import EventBus from '@/plugins/event-bus'
import { mapActions, mapGetters } from 'vuex'
import Loading from 'vue-loading-overlay'
import UnavailableAllowanceYears from '@/components/UnavailableAllowanceYears'

const NeedsSubscription = () => import('@/components/NeedsSubscription')
const AccountVerification = () => import('@/components/AccountVerification')
const CalendarServiceReConnector = () =>
  import('@/components/calendar-services/CalendarServiceReConnector')
const PaymentIssue = () => import('@/components/PaymentIssue')

export default {
  name: 'AppLayout',

  components: {
    Loading,
    AppModal,
    AppNavbar,
    HelpModal,
    SlideOver,
    AppFooter,
    LeaveDatesTour,
    NeedsSubscription,
    AccountVerification,
    CalendarServiceReConnector,
    PaymentIssue,
    UnavailableAllowanceYears,
  },

  mixins: [HandleAuth, Subscription, SystemCommonShortcuts, FeatureFlags],

  data() {
    return {
      isLoading: false,
    }
  },

  computed: {
    ...mapGetters('auth', ['companies', 'activeCompany']),

    failedCalendarServices() {
      return this.activeEmployment.failed_calendar_services.filter(
        calendar => !calendar.isDismissed
      )
    },

    paymentErrorFeatureFlag() {
      return this.isOn('dev-551-global-payment-error')
    },

    isAdmin() {
      return this.activeEmployment && this.activeEmployment.is_admin
    },

    isLoadingState() {
      return this.isLoading
    },
  },

  watch: {
    '$route.query': {
      async handler(newVal, oldVal) {
        if (isEqual(newVal, oldVal)) return

        new SlideoutPanel(this).handle()
      },
      immediate: true,
    },

    '$route.query.company': {
      async handler(companyKey) {
        if (companyKey && companyKey !== this.activeCompany.id) {
          this.isLoading = true

          await this.fetchCompany({ id: companyKey })

          this.isLoading = false
        }
      },
      immediate: true,
    },
  },

  mounted() {
    this.fetchFeedPublishDate()

    const interval = setInterval(
      () => this.fetchFeedPublishDate(),
      5 * 60 * 60 * 1000
    )

    const onError = error => {
      if (
        error.response &&
        includes(
          [HTTP_STATUS_UNAUTHORIZED, HTTP_STATUS_AUTHENTICATION_EXPIRED],
          error.response.status
        )
      ) {
        location.href = '/signin?reason=session_timeout'
      }

      return Promise.reject(error)
    }

    const onSuccess = response => response

    http.interceptors.response.use(onSuccess, onError)

    this.$once('hook:beforeDestroy', () => {
      clearInterval(interval)
    })
  },

  methods: {
    ...mapActions('auth', ['fetchCompany']),

    async fetchFeedPublishDate() {
      let feedPublishedAt = await Feed.getPublishedDate()

      if (feedPublishedAt === null) return

      EventBus.$emit('feed-updated', feedPublishedAt)
    },
  },
}
</script>
