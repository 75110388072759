export default class BillingPlan {
  constructor(data, features) {
    this.plan = data.plan
    this.displayName = data.display_name
    this.features = features
  }

  isSame(planName) {
    return (
      this.displayName.trim().toLowerCase() === planName.trim().toLowerCase()
    )
  }

  hasFeature(feature) {
    return this.features.includes(feature)
  }
}
