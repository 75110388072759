import http from '@/plugins/http'
import Company from '@/models/company/Company'
import { Billing } from '@/api'

export default class CompanyProfile {
  static async show(company) {
    const { data: response } = await http.get(`me/companies/${company.id}`)
    const billingPlans = await Billing.fetchBillingPlans()

    response.billing_plan = billingPlans.wherePlanName(
      response.current_billing_plan
    )[0]

    return new Company(response)
  }
}
