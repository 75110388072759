<template>
  <a
    ref="reference"
    class="tw-flex tw-items-center tw-cursor-pointer tw-no-underline tw-text-gray-800 tw-px-6 tw-py-5"
  >
    <span
      :title="activeCompany.name"
      class="tw-font-normal tw-truncate"
      style="max-width: 270px;"
      data-cy="company-switcher"
      v-text="activeCompany.name"
    />
    <SvgIcon
      name="cheveron-down"
      class="tw-w-6 tw-h-6 tw-ml-1 tw--mr-2 tw-text-gray-800"
    />

    <div v-show="false" ref="tooltip">
      <div class="tw-rounded" data-cy="company-switcher-list">
        <div class="tw-border-b tw-border-gray-300 tw-px-4 tw-pt-2">
          <div class="tw-flex tw-items-center tw-justify-start tw-relative">
            <span
              class="tw-pb-2 tw-text-gray-800 tw-text-thin tw-font-semibold tw-cursor-default"
              >Companies</span
            >
            <button
              class="tw-opacity-50 hover:tw-opacity-100 modal-close tw-absolute tw-right-0 tw-top-0"
              @click="hideTippy"
            >
              <SvgIcon name="close" style="width: 12px; height: 12px;" />
            </button>
          </div>
        </div>
        <div
          class="tw-overflow-auto"
          style="max-height: 588px; min-width: 210px;"
        >
          <div
            v-for="company in sortedCompanies"
            :key="company.id"
            class="company-link tw-flex tw-items-center tw-justify-between hover:tw-bg-gray-300"
            data-cy="company-switcher-company"
          >
            <a
              class="tw-block tw-w-full tw-p-4 tw-no-underline tw-cursor-pointer tw-overflow-hidden"
              @click="
                () => {
                  changeActiveCompany(company)
                }
              "
            >
              <span class="tw-font-normal" v-text="company.name" />
            </a>
            <div
              v-if="sortedCompanies.length > 1"
              class="tw-flex tw-items-center tw-justify-center tw-px-6"
            >
              <input
                :id="company.id"
                name="default-company"
                data-cy="default-company-selector"
                type="checkbox"
                class="star-checkbox"
                :checked="company.id === defaultCompanyId"
                @change="
                  changeDefaultCompany($event.target.checked, company.id)
                "
              />
              <label :for="company.id"></label>
            </div>
          </div>
        </div>
        <div
          v-if="isActiveEmploymentAnAdmin"
          class="tw-border-t tw-border-gray-300"
          data-cy="manage-companies"
        >
          <button
            class="tw-px-4 tw-py-2 tw-cursor-pointer tw-text-blue-500 tw-font-normal tw-overflow-hidden tw-no-underline hover:tw-underline"
            type="button"
            title="Manage Companies [alt+c]"
            data-cy="btn-manage-companies"
            @click="manageCompanies"
          >
            Manage Companies
          </button>
        </div>
      </div>
    </div>
  </a>
</template>

<script>
import tippy from 'tippy.js'
import { orderBy } from 'lodash-es'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'CompanySwitcher',

  computed: {
    ...mapGetters('auth', ['companies']),

    sortedCompanies() {
      return orderBy(
        this.companies,
        [company => company.name.toLowerCase()],
        'asc'
      )
    },

    defaultCompanyId: {
      get() {
        return this.authUser.settings.default_company
      },
      set(defaultCompanyId) {
        this.setAuthUser({
          ...this.authUser,
          settings: {
            ...this.authUser.settings,
            default_company: defaultCompanyId,
          },
        })
      },
    },
  },

  mounted() {
    this.tippy = tippy.one(this.$refs.reference, {
      html: this.$refs.tooltip.firstChild,
      trigger: 'click',
      distance: -10,
      placement: 'bottom-end',
      offset: '-12,18',
      interactive: true,
      theme: 'light',
      animation: 'scale',
      inertia: true,
      arrow: true,
    })

    this.$once('hook:beforeDestroy', () => {
      this.tippy.destroy()
    })
  },

  methods: {
    ...mapMutations('auth', ['setAuthUser']),

    hideTippy() {
      this.tippy.hide()
    },

    async changeActiveCompany(company) {
      this.$router.push(
        {
          query: {
            company: company.id,
          },
        },
        () => {}
      )

      this.tippy.hide()
    },

    manageCompanies() {
      this.$router.push({ name: 'companies' }, () => {})

      this.tippy.hide()
    },

    changeDefaultCompany(isChecked, companyId) {
      this.defaultCompanyId = isChecked ? companyId : null

      this.$emit('default-company-changed', this.defaultCompanyId)
    },
  },
}
</script>

<style scoped>
.star-checkbox {
  position: absolute;
  display: none;
}

.star-checkbox + label {
  position: relative;
  display: block;
  cursor: pointer;
  vertical-align: middle;
}

.star-checkbox + label:before {
  position: absolute;
  display: inline-block;
  top: -15px;
  left: -7px;
  content: ' ';
  font-size: 19px;
  color: transparent;
}

.star-checkbox:checked + label:before,
.company-link:hover .star-checkbox:checked + label:before,
.company-link:hover .star-checkbox:not(:checked) + label:hover:before {
  content: '\2605';
  color: #f6ad55;
}

.company-link:hover .star-checkbox:not(:checked) + label:before {
  content: '\2605';
  color: #b6bdc9;
}
</style>
