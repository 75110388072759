<template>
  <vSelect
    v-model="selectedOption"
    :options="employments"
    :multiple="false"
    :searchable="searchable"
    :show-labels="false"
    :allow-empty="false"
    :max-height="maxHeight"
    :data-cy="cypressAttribute"
    track-by="id"
    :label="label"
    :placeholder="placeholder"
    v-bind="groupByAttributes"
  >
    <template #singleLabel="props">
      <span v-if="showAvatars" class="tw-flex tw-items-center">
        <UserAvatar class="tw-mr-2" size="sm" :user="props.option" />

        {{ props.option.full_name ?? props.option.name }}
      </span>
      <span v-else>{{ props.option.full_name ?? props.option.name }}</span>
    </template>

    <template #option="props">
      <span
        v-if="props.option.$isLabel"
        class="tw-font-semibold md:tw-truncate tw-text-blue-600"
      >
        {{ props.option.$groupLabel }}
      </span>
      <span v-else>
        {{ props.option.full_name ?? props.option.name }}
      </span>
    </template>

    <span v-if="searchable" slot="noResult">No results were found.</span>
  </vSelect>
</template>

<script>
import commonOptions from '@/components/pickers/commonOptions'
import { groupBy, head, map } from 'lodash-es'

export default {
  name: 'EmploymentPicker',

  mixins: [commonOptions],

  props: {
    searchable: {
      type: Boolean,
      default: false,
    },

    showAvatars: {
      type: Boolean,
      default: false,
    },

    groupByDepartment: {
      type: Boolean,
      default: false,
    },

    maxHeight: {
      type: Number,
      default: 180,
    },
  },

  computed: {
    employments() {
      if (this.groupByDepartment) {
        const groupedEmployments = groupBy(this.options, 'department.name')

        return map(groupedEmployments, employments => {
          const department = head(employments).department?.id
            ? head(employments).department.name
            : 'No Department'

          return {
            department: department,
            employments: employments,
          }
        })
      }

      return this.options
    },

    label() {
      return this.selectedOption?.hasOwnProperty('full_name')
        ? 'full_name'
        : 'name'
    },

    placeholder() {
      return this.searchable ? 'Search and select' : ''
    },

    groupByAttributes() {
      if (this.groupByDepartment) {
        return {
          groupValues: 'employments',
          groupLabel: 'department',
        }
      }

      return {}
    },
  },
}
</script>
