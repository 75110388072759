import BillingPlan from '@/models/Billing/BillingPlan'

export default class BillingPlanCollection {
  constructor(data) {
    this.plans = data.plans.map(plan => {
      const planWithFeatures = data.plan_features.find(
        planWithFeatures => planWithFeatures.plan === plan.plan
      )

      return new BillingPlan(plan, planWithFeatures.features)
    })
  }

  wherePlanName(planName) {
    return this.plans.filter(plan => plan.isSame(planName))
  }
}
